import React from "react";
import { Helmet } from "react-helmet-async";
import Stack from "theme/components/atoms/Layout/Stack";
import SharedSlicesZone from "theme/modules/Slices/Shared";
import EnhanceHome from "./EnhanceHome";

export const HomePage = ({ error, data }) => {
  if (error) {
    return <div>Oops, something went wrong.</div>;
  }

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.meta.description} />
        <meta name="keywords" content={data.meta.keywords} />
      </Helmet>

      <SharedSlicesZone
        content={data.mainContent}
        renderSlices={(slices) => (
          <Stack size="8">{slices.map((slice) => slice)}</Stack>
        )}
      />
    </>
  );
};

export default EnhanceHome()(HomePage);
