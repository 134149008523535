import compose from "recompose/compose";
import graphqlWithPreload from "web/core/apollo/graphqlWithPreload";
import Loadable from "theme/components/helpers/Loadable";
import HomeQuery from "./HomeQuery.gql";

export default () =>
  compose(
    graphqlWithPreload(HomeQuery, {
      props: ({ data }) => ({
        loading: data.loading,
        error: data.error,
        data: !data.loading && !data.error && data.homePage,
      }),
    }),
    Loadable((props) => !props.loading)
  );
